import { gql } from '@apollo/client/core';

import { ADDRESS_FRAGMENT } from '@/entities/address/graphql/fragments/address.fragment';
import { VEHICLE_FRAGMENT } from '@/entities/vehicle/lib/graphql/fragments/vehicle.fragment';
import { EMAIL_FRAGMENT } from '@/shared/graphql/fragments/email.fragment';
import { PHONE_FRAGMENT } from '@/shared/graphql/fragments/phone.fragment';
import { RATES_FRAGMENT } from '@/shared/graphql/fragments/rates.fragment';
import { TAX_FRAGMENT } from '@/shared/graphql/fragments/tax.fragment';

export const FULL_NAME_FRAGMENT = gql`
	fragment FullNameFields on FullName {
		id
		first
		last
		middle
	}
`;

export const CONSUMER_SHOP_SETTINGS_FRAGMENT = gql`
	fragment ConsumerShopSettingsFields on ConsumerShopSettings {
		id
		consumer_id
		shop_id
		pricelevel_id
		rates {
			...RatesFields
		}
		override_allowed_shop
	}
	${RATES_FRAGMENT}
`;

export const CONSUMER_CONFIRMATION_SETTINGS_FRAGMENT = gql`
	fragment ConsumerConfirmationSettingsFields on ConsumerConfirmationSettings {
		send
	}
`;

export const CONSUMER_BASE_FRAGMENT = gql`
	fragment ConsumerBaseFields on Consumer {
		id
		company_name
		status
		is_commercial
		is_insurance
		tax_exempt
		organization_id
		notes
		auto_popup
		auto_popup_message
		override_allowed_shops
		po_required
		auto_add_adhesive
		auto_add_to_fleet
		flagged
		default_invoice_tier
		fax
		reserve_balance
		website
		guest
		name {
			...FullNameFields
		}
		phones {
			...PhoneFields
		}
		address {
			...AddressFields
		}
		standing_po
		auto_set_install_address
		resale_id
	}
	${FULL_NAME_FRAGMENT}
`;

export const CONSUMER_EDI_FRAGMENT = gql`
	fragment ConsumerEdiFields on ConsumerEdi {
		capable
		trading_partner
		partner_program_id
		program_name
	}
`;

export const CONSUMER_FRAGMENT = gql`
	fragment ConsumerFields on Consumer {
		...ConsumerBaseFields
		names {
			...FullNameFields
		}
		phone {
			...PhoneFields
		}
		secondary_phone {
			...PhoneFields
		}

		email {
			...EmailFields
		}
		emails {
			...EmailFields
		}

		addresses {
			...AddressFields
		}
		consumer_shop_settings {
			...ConsumerShopSettingsFields
		}
		consumer_confirmation_settings {
			...ConsumerConfirmationSettingsFields
		}
		taxes {
			...TaxFields
		}
		vehicles {
			...VehicleFields
		}
		edi {
			...ConsumerEdiFields
		}
		bill_to {
			id
			full_name
		}
		salesrep {
			id
			full_name
		}
		tech {
			id
			full_name
		}
		install_location {
			id
			address {
				...AddressFields
			}
		}
	}
	${CONSUMER_BASE_FRAGMENT}
	${FULL_NAME_FRAGMENT}
	${PHONE_FRAGMENT}
	${EMAIL_FRAGMENT}
	${ADDRESS_FRAGMENT}
	${TAX_FRAGMENT}
	${VEHICLE_FRAGMENT}
	${CONSUMER_EDI_FRAGMENT}
	${CONSUMER_SHOP_SETTINGS_FRAGMENT}
	${CONSUMER_CONFIRMATION_SETTINGS_FRAGMENT}
`;

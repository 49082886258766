import {
	ComparisonType,
	CustomersFiltersWithMetadata,
	CustomersTableHeader,
	CustomerTable
} from '@/entities/customer/lib/types';

export const customersHeader: CustomersTableHeader[] = [
	{ key: 'id', title: 'Id' },
	{ key: 'edit', title: 'Edit' },
	{ key: 'status', title: 'Status' },
	{ key: 'name_first', title: 'First Name' },
	{ key: 'name_last', title: 'Last Name' },
	{ key: 'customer_type', title: 'Type' },
	{ key: 'company_name', title: 'Company Name' },
	{ key: 'phone', title: 'Primary Phone' },
	{ key: 'secondary_phone', title: 'Secondary Phone' },
	{ key: 'email', title: 'Email' },
	{ key: 'full_address', title: 'Full Address' },
	{ key: 'notes', title: 'Notes' },
	{ key: 'fax', title: 'Fax' },
	{ key: 'tax_exempt', title: 'Tax Exempt?' },
	{ key: 'po_required', title: 'PO Required?' }
];

export type NormalizedCustomer = Pick<
	CustomerTable,
	| 'id'
	| 'status'
	| 'flagged'
	| 'customer_type'
	| 'name_first'
	| 'name_last'
	| 'company_name'
	| 'phone'
	| 'secondary_phone'
	| 'full_address'
	| 'fax'
	| 'tax_exempt'
	| 'po_required'
	| 'notes'
	| 'email'
>;

export const customersFilters: CustomersFiltersWithMetadata<NormalizedCustomer> =
	{
		id: { val: null, type: ComparisonType.like },
		status: { val: null, type: ComparisonType.exact },
		flagged: { val: null, type: ComparisonType.exact },
		customer_type: { val: null, type: ComparisonType.exact },
		name_first: { val: null, type: ComparisonType.like },
		name_last: { val: null, type: ComparisonType.like },
		company_name: { val: null, type: ComparisonType.like },
		phone: { val: null, type: ComparisonType.like },
		secondary_phone: { val: null, type: ComparisonType.like },
		full_address: { val: null, type: ComparisonType.like },
		fax: { val: null, type: ComparisonType.like },
		tax_exempt: { val: null, type: ComparisonType.exact },
		po_required: { val: null, type: ComparisonType.exact },
		notes: { val: null, type: ComparisonType.like },
		email: { val: null, type: ComparisonType.like }
	};

export const TABLE_ID = 'customersTable';
export const ITEMS_PER_REQUEST = 50;

export const flaggedOptions = [
	{ id: 'yes', name: 'Flagged' },
	{ id: '0', name: 'Not Flagged' }
];

export const exemptOptions = [
	{ id: 'yes', name: 'Exempt' },
	{ id: '0', name: 'Not Exempt' }
];

export const requiredOptions = [
	{ id: 'yes', name: 'Required' },
	{ id: '0', name: 'Not Required' }
];

export const avatarColorMap: { [key: string]: string } = {
	a: 'tw3-bg-cyan-400',
	b: 'tw3-bg-blue-500',
	c: 'tw3-bg-green-500',
	d: 'tw3-bg-yellow-500',
	e: 'tw3-bg-purple-500',
	f: 'tw3-bg-pink-500',
	g: 'tw3-bg-indigo-500',
	h: 'tw3-bg-teal-500',
	i: 'tw3-bg-orange-500',
	j: 'tw3-bg-lime-500',
	k: 'tw3-bg-emerald-500',
	l: 'tw3-bg-cyan-500',
	m: 'tw3-bg-sky-500',
	n: 'tw3-bg-violet-500',
	o: 'tw3-bg-fuchsia-500',
	p: 'tw3-bg-rose-500',
	q: 'tw3-bg-emerald-400',
	r: 'tw3-bg-blue-400',
	s: 'tw3-bg-green-400',
	t: 'tw3-bg-yellow-400',
	u: 'tw3-bg-purple-400',
	v: 'tw3-bg-pink-400',
	w: 'tw3-bg-indigo-400',
	x: 'tw3-bg-teal-400',
	y: 'tw3-bg-orange-400',
	z: 'tw3-bg-lime-400'
};

export const avatarDefaultColor = 'tw3-bg-gray-200';

const sgcAddress = {
	address: '',
	city: 'Columbus',
	full_address: 'Columbus, OH 43218',
	google_place_id: 'ChIJ6zh5pSKKOIgRgoVNGR-gGhM',
	lat: 40.0151339,
	lng: -82.9246084,
	po_box: 1,
	state: 'OH',
	unit: '182277',
	zip: '43218'
};

const sgcEmail = {
	email: 'invoicingfax@safelite.com'
};

const sgcPhone = {
	number: '(614) 602-2120'
};

export const sgcFields = {
	addresses: [sgcAddress],
	emails: [sgcEmail],
	website: 'https://sgc.safelite.com',
	phones: [sgcPhone]
};

const lynxAddress = {
	address: '6351 Bayshore Rd',
	city: 'North Fort Myers',
	full_address: '6351 Bayshore Rd, North Fort Myers, FL 33917',
	google_place_id: 'ChIJ3cwYc85p24gRUjHN3CI1LX0',
	lat: 26.697866,
	lng: -81.853935,
	po_box: 0,
	state: 'FL',
	unit: '18',
	zip: '33917'
};

const lynxPhone = {
	number: '(239) 479-6000'
};

export const lynxFields = {
	addresses: [lynxAddress],
	website: 'https://www.lynxservices.com',
	phones: [lynxPhone],
	fax: null,
	emails: []
};

const isDev =
	process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging';

let secure;
if (process.env.VUE_APP_SECURE !== undefined) {
	secure = process.env.VUE_APP_SECURE !== 'false';
} else {
	secure = window.location.protocol == 'https:';
}

const host = process.env.VUE_APP_API_HOST || window.location.host;

const protocol = secure ? 'https' : 'http';
const uri = `${protocol}://${host}`;

export default {
	clover: {
		clientId: process.env.VUE_APP_CLOVER_CLIENT_ID,
		serverUrl: process.env.VUE_APP_CLOVER_SERVER_URL
	},

	isDev,
	uri
};

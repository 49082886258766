import { gql } from '@apollo/client/core';

import { CONSUMER_FRAGMENT } from '../fragments/consumer.fragment';

export const GET_CONSUMER_QUERY = gql`
	query getConsumer($id: ID) {
		consumer(id: $id) {
			...ConsumerFields
		}
	}
	${CONSUMER_FRAGMENT}
`;

import axios from 'axios';

import { getAuth } from '@/shared/auth';

import { API_URI } from '../constants/api';

const CORE_API = axios.create({
	baseURL: API_URI,
	headers: {
		Authorization: `Bearer ${getAuth('access_token')}`
	}
});

CORE_API.interceptors.response.use(
	async config => {
		//@ts-ignore
		const token = await window.auth.getTokenSilently();
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		return config;
	},
	async error => {
		const originalRequest = error.config;

		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;

			try {
				//@ts-ignore
				const newToken = await window.auth.getTokenSilently();

				originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
				return CORE_API(originalRequest);
			} catch (tokenRefreshError) {
				return Promise.reject(tokenRefreshError);
			}
		}
		return Promise.reject(error);
	}
);

export { CORE_API };

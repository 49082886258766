<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';

const isMob = ref(false);
const contentWidth = ref(48);

const left = computed(() =>
	isMob.value && contentWidth.value < 110
		? contentWidth.value / 2
		: contentWidth.value
);

const updatePosition = (entries: ResizeObserverEntry[]) => {
	contentWidth.value = entries[0].contentRect.width;
};

const resizeObserver = new ResizeObserver(updatePosition);
const resizeHandle = () => {
	isMob.value = window.innerWidth < 600;
};

onMounted(() => {
	const interval = setInterval(() => {
		const el = document.getElementById('main-sidebar');
		if (el === null) {
			return;
		}
		resizeObserver.observe(el!);
		clearInterval(interval);
		resizeHandle();
		window.addEventListener('resize', resizeHandle);
	}, 500);
});

onUnmounted(() => {
	resizeObserver.disconnect();
	window.removeEventListener('resize', resizeHandle);
});
</script>

<template>
	<div
		id="vue3-main-container"
		class="px-4 py-3"
		:style="{ left: `${left}px` }"
	>
		<router-view />
	</div>
</template>

<style>
#vue3-main-container {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 56px;
	left: 48px;
	right: 0px;
	bottom: 0px;
	z-index: 10;
	overflow: auto;
}
</style>

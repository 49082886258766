export const formatNumber = (num: number) => {
	let formattedNumber;

	if (num >= 1_000_000) {
		formattedNumber = Math.round(num / 10_000) / 100 + 'm';
	} else if (num >= 1_000) {
		formattedNumber = Math.round(num / 100) / 10 + 'k';
	} else {
		formattedNumber = num.toFixed(0);
	}

	return formattedNumber.toString();
};

export const formatPrice = (num: number | string) => {
	return `$${num}`;
};

import { CORE_API } from '@/shared/api';

import { TimeRange } from './enums';
import {
	DashboardLifetimeData,
	DashboardLifetimeObjectNumber,
	DashboardLifetimeObjectString,
	DashboardMainGraph,
	NewestInvoices
} from './types';

const DASHBOARD_INVOICE_URL = '/unum/dashboard/invoice';
const DASHBOARD_CUSTOMER_URL = '/unum/dashboard/customer';

export const getLatestInvoices = async () => {
	try {
		const response = await CORE_API.get<{ items: NewestInvoices[] }>(
			`${DASHBOARD_INVOICE_URL}/last-list`
		);

		return response.data.items;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getRevenueAndInvoicesAmount = async (params: {
	shop_ids?: number[];
	time_range: TimeRange;
}) => {
	try {
		const response = await CORE_API.post<DashboardMainGraph>(
			`${DASHBOARD_INVOICE_URL}/revenue-and-amount`,
			params
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getFilteredNumberOfInvoices = async (params: {
	shop_ids?: number[];
	time_range: TimeRange;
}) => {
	try {
		const response = await CORE_API.post<{ items: DashboardLifetimeData[] }>(
			`${DASHBOARD_INVOICE_URL}/filtered-number`,
			params
		);

		return response.data.items;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getFilteredAverageOfInvoices = async (params: {
	shop_ids?: number[];
	time_range: TimeRange;
}) => {
	try {
		const response = await CORE_API.post<{ items: DashboardLifetimeData[] }>(
			`${DASHBOARD_INVOICE_URL}/filtered-average`,
			params
		);

		return response.data.items;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getFilteredNumberOfCustomers = async (params: {
	shop_ids?: number[];
	time_range: TimeRange;
}) => {
	try {
		const response = await CORE_API.post<{ items: DashboardLifetimeData[] }>(
			`${DASHBOARD_CUSTOMER_URL}/filtered-number`,
			params
		);

		return response.data.items;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getTotalAndNumberOfInvoices = async () => {
	try {
		const response = await CORE_API.get<DashboardLifetimeObjectString>(
			`${DASHBOARD_INVOICE_URL}/total-and-number`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getAverageAndNumberOfInvoices = async () => {
	try {
		const response = await CORE_API.get<DashboardLifetimeObjectNumber>(
			`${DASHBOARD_INVOICE_URL}/average-and-number`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getAmountAndRevenueOfInvoices = async () => {
	try {
		const response = await CORE_API.get<DashboardLifetimeObjectNumber>(
			`${DASHBOARD_INVOICE_URL}/amount-and-revenue`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getTotalAndNumberOfCustomers = async () => {
	try {
		const response = await CORE_API.get<DashboardLifetimeObjectString>(
			`${DASHBOARD_CUSTOMER_URL}/total-and-number`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const removeTypenameFields = (obj: any) => {
	if (Array.isArray(obj)) {
		for (let i = 0; i < obj.length; i++) {
			obj[i] = removeTypenameFields(obj[i]);
		}
	} else if (typeof obj === 'object' && obj !== null) {
		const newObj = { ...obj };
		for (const key in newObj) {
			if (Object.prototype.hasOwnProperty.call(newObj, key)) {
				if (key === '__typename') {
					delete newObj[key];
				} else {
					newObj[key] = removeTypenameFields(newObj[key]);
				}
			}
		}
		return newObj;
	}
	return obj;
};

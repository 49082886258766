export type TableHeader = {
	key: string;
	title: string;
	sortable?: boolean;
};

export enum SortBy {
	id = 'id',
	customerName = 'customer_name',
	customerPhone = 'customer_phone',
	vehicle = 'vehicle_full',
	followupDate = 'f_followup_date',
	quoteDate = 'f_quote_date',
	amount = 'amount',
	gbCsrId = 'csr'
}

export type FiltersWithMetadata<T> = {
	[K in keyof T]: {
		value: string | boolean | null;
		matchMode: string;
	};
};

export const insuranceClaimCodes = [
	{
		title: '101 - Rock from Road - No One at Fault',
		value: 'CAUSE_OF_LOSS_101'
	},
	{
		title: '105 - Rock from Road - 3rd Party Fault License #',
		value: 'CAUSE_OF_LOSS_105'
	},
	{
		title: '111 - Animal',
		value: 'CAUSE_OF_LOSS_111'
	},
	{
		title: '121 - Tree Branch',
		value: 'CAUSE_OF_LOSS_121'
	},
	{
		title: '131 - Garage Door',
		value: 'CAUSE_OF_LOSS_131'
	},
	{
		title: '199 - Other Object',
		value: 'CAUSE_OF_LOSS_199'
	},
	{
		title: '201 - Collision',
		value: 'CAUSE_OF_LOSS_201'
	},
	{
		title: '301 - Vandalism – 3rd Party Known',
		value: 'CAUSE_OF_LOSS_301'
	},
	{
		title: '311 - Vandalism – 3rd Party Unknown',
		value: 'CAUSE_OF_LOSS_311'
	},
	{
		title: '321 - Terrorism',
		value: 'CAUSE_OF_LOSS_321'
	},
	{
		title: '331 - Attempted Theft',
		value: 'CAUSE_OF_LOSS_331'
	},
	{
		title: '341 - Theft',
		value: 'CAUSE_OF_LOSS_341'
	},
	{
		title: '401 - Extreme Heat or Cold Weather',
		value: 'CAUSE_OF_LOSS_401'
	},
	{
		title: '411 - Hail Storm',
		value: 'CAUSE_OF_LOSS_411'
	},
	{
		title: '421 - Hurricane',
		value: 'CAUSE_OF_LOSS_421'
	},
	{
		title: '431 - Earthquake',
		value: 'CAUSE_OF_LOSS_431'
	},
	{
		title: '499 - Other Storm',
		value: 'CAUSE_OF_LOSS_499'
	},
	{
		title: '901 - Unknown',
		value: 'CAUSE_OF_LOSS_901'
	}
];

type ObjectWithField = Record<string, { [key: string]: any }>;

export const filterEmptyFields = (
	objects: Record<string, ObjectWithField>,
	fieldName: string,
	fullObject = false
): Record<string, any> => {
	return Object.entries(objects).reduce<Record<string, any>>(
		(acc, [key, obj]) => {
			if (obj !== undefined && obj[fieldName]) {
				acc[key] = fullObject ? obj : obj[fieldName];
			}
			return acc;
		},
		{}
	);
};

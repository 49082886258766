import { onMounted, onUnmounted, ref } from 'vue';

import type { Ref } from 'vue';

export const useEventListener = <T>(name: string): Ref<boolean | T> => {
	const dataFromCustomEvent = ref(false);

	const handler = (event: CustomEvent<boolean>): void => {
		dataFromCustomEvent.value = event.detail;
	};

	onMounted(() => {
		window.addEventListener(name, handler as EventListener);
	});

	onUnmounted(() => {
		window.removeEventListener(name, handler as EventListener);
	});

	return dataFromCustomEvent;
};

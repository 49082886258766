import { gql } from '@apollo/client/core';

import { CONSUMER_FRAGMENT } from '../fragments/consumer.fragment';

export const CREATE_CONSUMER_MUTATION = gql`
	mutation createConsumer($consumer: ConsumerInput!) {
		createConsumer(consumer: $consumer) {
			...ConsumerFields
		}
	}
	${CONSUMER_FRAGMENT}
`;

export const UPDATE_CONSUMER_MUTATION = gql`
	mutation updateConsumer($consumer_id: ID!, $consumer: ConsumerInput!) {
		updateConsumer(consumer_id: $consumer_id, consumer: $consumer) {
			...ConsumerFields
		}
	}
	${CONSUMER_FRAGMENT}
`;

<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import Button from 'primevue/button';
import InputGroup from 'primevue/inputgroup';
import InputMask from 'primevue/inputmask';
import { useField, useForm } from 'vee-validate';
import { nextTick, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import * as zod from 'zod';

import { useCustomerEditStore } from '@/entities/customer/lib/store';
import { InputTextWithError } from '@/shared/ui';

const ZOD_COMPANY_NAME = 'companyName';
const ZOD_WEBSITE = 'website';
const ZOD_FAX = 'fax';

const { t } = useI18n();

const props = defineProps<{
	companyName: string | null;
	website?: string | null;
	fax?: string | null;
	isCommercial: number | null;
}>();

const emit = defineEmits<{
	(e: 'update:companyName', c: string | null): void;
	(e: 'update:website', c: string | null): void;
	(e: 'update:fax', c: string | null): void;
	(e: 'update:isCommercial', c: number): void;
}>();

const store = useCustomerEditStore();

const validationSchema = toTypedSchema(
	zod.object({
		[ZOD_COMPANY_NAME]: zod.string().nullish(),
		[ZOD_WEBSITE]: zod.string().nullish(),
		[ZOD_FAX]: zod.string().max(14, t('errorNumber')).nullish()
	})
);

useForm({
	initialValues: {
		[ZOD_COMPANY_NAME]: props.companyName,
		[ZOD_WEBSITE]: props.website?.split('//').at(-1),
		[ZOD_FAX]: props.fax
	},
	validationSchema
});

const domen = ref<string>(
	props.website?.split('//').at(0)
		? props.website.split('//').at(0) + '//'
		: 'https://'
);

const {
	errorMessage: companyNameError,
	value: companyNameValue,
	setValue: setValueCompany
} = useField(ZOD_COMPANY_NAME);
const {
	errorMessage: websiteError,
	value: websiteValue,
	setValue: setValueSite
} = useField(ZOD_WEBSITE);
const { errorMessage: faxError, value: faxValue } = useField(ZOD_FAX);

const updateDomen = () => {
	domen.value = domen.value === 'https://' ? 'http://' : 'https://';
	if (!websiteValue.value) return;
	emit('update:website', domen.value + websiteValue.value);
};

const updateCompanyName = async (v: string | null) => {
	const input = document.getElementById('companyNameInput') as HTMLInputElement;
	const cursorPosition = input.selectionStart;
	emit(
		'update:companyName',
		v
			? v
					.split(' ')
					.map(word => word.charAt(0).toUpperCase() + word.slice(1))
					.join(' ')
			: v
	);
	await nextTick();
	input.setSelectionRange(cursorPosition, cursorPosition);
};

watch(
	() => companyNameError.value,
	() =>
		store.updateErrors(
			'company_name',
			companyNameError.value ? { error: companyNameError.value } : {}
		)
);

watch(
	() => websiteError.value,
	() =>
		store.updateErrors(
			'website',
			websiteError.value ? { error: websiteError.value } : {}
		)
);

watch(
	() => faxError.value,
	() =>
		store.updateErrors('fax', faxError.value ? { error: faxError.value } : {})
);

watch(
	() => props.website,
	() => {
		setValueSite(props.website?.split('//').at(-1));
		domen.value = props.website?.split('//').at(0)
			? props.website.split('//').at(0) + '//'
			: 'https://';
	}
);

watch(
	() => props.companyName,
	() => setValueCompany(props.companyName)
);
</script>

<template>
	<div class="tw3-flex tw3-gap-[1rem] tw3-px-[0.5rem]">
		<div class="tw3-w-full tw3-max-w-64">
			<div
				class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
			>
				Company Name
			</div>
			<InputTextWithError
				id="companyNameInput"
				:disabled="!isCommercial"
				:errMsg="companyNameError"
				:modelValue="companyNameValue"
				placeholder="Company Name"
				@update:model-value="updateCompanyName"
			/>
		</div>
		<div class="tw3-w-full tw3-max-w-64">
			<div
				class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
			>
				Website
			</div>
			<InputGroup>
				<Button :label="domen" severity="secondary" @click="updateDomen" />
				<InputTextWithError
					v-model="websiteValue"
					:disabled="!isCommercial"
					:errMsg="websiteError"
					placeholder="Website"
					@update:model-value="
						v => emit('update:website', v ? domen + v : null)
					"
				/>
			</InputGroup>
		</div>
		<div class="tw3-w-full tw3-max-w-64">
			<div
				class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
			>
				Fax
			</div>
			<InputMask
				v-model="faxValue"
				class="tw3-w-full tw3-max-w-64"
				:disabled="!isCommercial"
				:invalid="!!faxError"
				mask="(999) 999-9999"
				placeholder="Fax"
				type="text"
				@update:model-value="v => emit('update:fax', v ?? null)"
			/>
		</div>
	</div>
</template>

import { defaultOptions } from '@primevue/core/config';
import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';
import AnimateOnScroll from 'primevue/animateonscroll';
import BadgeDirective from 'primevue/badgedirective';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import FocusTrap from 'primevue/focustrap';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';

const config = {
	components: {
		tag: {
			padding: `${0.25 * (14 / 16)}rem ${0.5 * (14 / 16)}rem`
		},
		dialog: { header: { padding: '0.75rem 1.25rem' } }
		// card: { body: { gap: '0px' } }
	}
};

const options = () => {
	const CustomPreset = definePreset(Aura, config);

	return {
		ripple: true,
		theme: {
			options: {
				cssLayer: false,
				darkModeSelector: 'none'
			},
			preset: CustomPreset
		},
		unstyled: false,

		zIndex: {
			menu: 11100, //overlay menus
			modal: 11000, //dialog, sidebar
			overlay: 10000, //dropdown, overlaypanel
			tooltip: 11101 //tooltip
		},
		locale: {
			...defaultOptions.locale,
			dateFormat: 'yy-mm-dd'
		}
	};
};

export const usePrimeVue = (app: any) => {
	app.use(PrimeVue, options());

	app.use(ConfirmationService);
	app.use(ToastService);
	app.use(DialogService);

	app.directive('badge', BadgeDirective);
	app.directive('tooltip', Tooltip);
	app.directive('ripple', Ripple);
	app.directive('styleclass', StyleClass);
	app.directive('focustrap', FocusTrap);
	app.directive('animateonscroll', AnimateOnScroll);
};

import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';

import { getMany } from '@/entities/insurance/lib/api';
import { InsuranceRequest } from '@/entities/insurance/lib/types';

export const useInsuranceStore = defineStore('insurances', () => {
	const insurances = useAsyncState(
		(args: InsuranceRequest) => {
			return getMany(args);
		},
		[],
		{
			immediate: false,
			shallow: false
		}
	);

	return { insurances };
});

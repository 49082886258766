<script setup lang="ts">
import { vInfiniteScroll } from '@vueuse/components';
import { startCase } from 'lodash-es';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable, { DataTableSortEvent } from 'primevue/datatable';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import Select from 'primevue/select';
import Skeleton from 'primevue/skeleton';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import {
	customersHeader,
	exemptOptions,
	flaggedOptions,
	requiredOptions,
	TABLE_ID
} from '@/entities/customer/lib/contants';
import { useCustomersStore } from '@/entities/customer/lib/store';
import { CustomerStatus, CustomerType } from '@/entities/customer/lib/types';
import useStorageStore from '@/features/customer/lib/storageStore';
import { isEmptyValue } from '@/shared/helpers';

const statusOptions = Object.values(CustomerStatus).map(v => ({
	id: v,
	name: startCase(v)
}));
const typeOptions = Object.values(CustomerType).map(v => ({
	id: v,
	name: v === CustomerType.Cash ? 'Retail' : v
}));

const emits = defineEmits<{
	(e: 'on-edit', id: string): void;
	(e: 'on-open-address', id: number, addressId: number): void;
}>();

const { t } = useI18n();
const store = useCustomersStore();
const storage = useStorageStore();

const showColumnSettings = ref(false);

const selectedColumns = computed(() =>
	storage.customerColumns.length ? storage.customerColumns : customersHeader
);

const getFilterIconClass = (field: string) => {
	const filter = store.filters[field];

	return isEmptyValue(filter.val) || filter.val === 'all'
		? 'pi pi-filter'
		: 'pi pi-filter-fill';
};

const handleSort = (state: DataTableSortEvent) => {
	store.handleSort({
		desc: state.sortOrder === 1,
		field: state.sortField as string
	});
};

onMounted(() => store.customers.execute(0, store.composeGetCustomersArgs(0)));
</script>

<template>
	<DataTable
		v-model:filters="store.filters"
		dataKey="id"
		filterDisplay="menu"
		lazy
		:pt:tableContainer:id="TABLE_ID"
		pt:thead:class="tw3-border-b"
		scrollable
		scrollHeight="flex"
		size="small"
		:sortField="store.sort.field"
		:sortOrder="store.sort.desc === 'desc' ? 1 : -1"
		tableClass="tw3-max-w-full"
		:totalRecords="store.customers.state.count"
		:value="
			store.customers.isLoading ||
			(!store.customers.state.customers.length && store.loadingMore)
				? new Array(40)
				: store.customers.state.customers
		"
		@sort="handleSort"
	>
		<template #header>
			<div class="tw3-flex tw3-items-center tw3-justify-between">
				<Button
					label="Export"
					:loading="store.exportingLoading"
					raised
					size="small"
					text
					@click="store.exportData"
				/>
				<Button
					icon="pi pi-objects-column"
					:loading="store.exportingLoading"
					severity="secondary"
					size="small"
					@click="showColumnSettings = true"
				/>
			</div>
		</template>
		<Column
			v-for="column of selectedColumns"
			:key="column.key"
			:field="column.key"
			:filterField="column.key"
			:header="column.key === 'edit' ? undefined : column.title"
			headerClass="tw3-whitespace-nowrap tw3-border-b"
			pt:headerCell:class="tw3-border-b"
			:showFilterMatchModes="false"
			:showFilterMenu="column.key !== 'edit'"
			:sortable="column.key !== 'edit'"
		>
			<template #filtericon>
				<i :class="getFilterIconClass(column.key)"></i>
			</template>
			<template #filter="{ filterModel }">
				<InputNumber
					v-if="column.key === 'id'"
					allowEmpty
					class="p-column-filter"
					:modelValue="filterModel.val"
					:placeholder="`Search by ${column.title}`"
					type="number"
					:useGrouping="false"
					@update:model-value="v => (filterModel.val = v ? String(v) : v)"
				/>
				<InputMask
					v-else-if="
						column.key === 'fax' ||
						column.key === 'phone' ||
						column.key === 'secondary_phone'
					"
					allowEmpty
					class="p-column-filter"
					mask="(999) 999-9999"
					:modelValue="filterModel.val"
					:placeholder="`Search by ${column.title}`"
					type="text"
					:useGrouping="false"
					@update:model-value="v => (filterModel.val = v ? String(v) : v)"
				/>
				<MultiSelect
					v-else-if="column.key === 'customer_type'"
					v-model="filterModel.val"
					class="p-column-filter"
					:maxSelectedLabels="2"
					optionLabel="name"
					:options="typeOptions"
					optionValue="id"
					:placeholder="`Search by ${column.title}`"
					:showToggleAll="false"
				/>
				<Select
					v-else-if="column.key === 'status'"
					class="p-column-filter"
					:modelValue="filterModel.val"
					optionLabel="name"
					:options="statusOptions"
					optionValue="id"
					:placeholder="`Search by ${column.title}`"
					@update:model-value="
						v => (filterModel.val = v === CustomerStatus.all ? null : v)
					"
				/>
				<Select
					v-else-if="
						column.key === 'flagged' ||
						column.key === 'tax_exempt' ||
						column.key === 'po_required'
					"
					v-model="filterModel.val"
					class="p-column-filter"
					optionLabel="name"
					:options="
						column.key === 'flagged'
							? flaggedOptions
							: column.key === 'tax_exempt'
								? exemptOptions
								: requiredOptions
					"
					optionValue="id"
					:placeholder="`Search by ${column.title}`"
				/>
				<InputText
					v-else
					v-model="filterModel.val"
					class="p-column-filter"
					:placeholder="`Search by ${column.title}`"
					type="text"
				/>
			</template>
			<template #filterclear="{ filterModel, filterCallback }">
				<Button
					label="Clear"
					outlined
					size="small"
					@click="
						() => {
							filterModel.val = null;
							filterCallback();
						}
					"
				/>
			</template>
			<template #body="{ data, index }">
				<Skeleton v-if="store.customers.isLoading || store.loadingMore" />
				<div v-else class="cell-container">
					<Button
						v-if="column.key === 'edit'"
						aria-label="Edit"
						severity="secondary"
						size="small"
						text
						type="button"
						@click="emits('on-edit', data['id'])"
					>
						<template #icon>
							<i class="material-icons md-edit" />
						</template>
					</Button>
					<a
						v-else-if="column.key === 'id'"
						href="#"
						@click="emits('on-edit', data[column.key])"
					>
						{{ data[column.key] }}
					</a>
					<div
						v-else-if="
							column.key === 'status' ||
							column.key === 'customer_type' ||
							column.key === 'flagged' ||
							column.key === 'tax_exempt' ||
							column.key === 'po_required'
						"
						class="tw3-flex-1 tw3-flex tw3-justify-center tw3-items-center"
					>
						<div
							v-if="column.key === 'status'"
							class="tw3-rounded tw3-text-white tw3-text-sm tw3-leading-3 tw3-capitalize tw3-shadow-md tw3-p-2"
							:class="
								!data[column.key] ||
								data[column.key] === CustomerStatus.inactive
									? 'tw3-bg-gray-500'
									: 'tw3-bg-green-500'
							"
						>
							{{ data[column.key] }}
						</div>
						<div
							v-else-if="column.key === 'customer_type'"
							class="tw3-rounded tw3-text-white tw3-text-sm tw3-leading-3 tw3-capitalize tw3-shadow-md tw3-p-2"
							:class="{
								'tw3-bg-yellow-500':
									data[column.key] === CustomerType.Commercial,
								'tw3-bg-cyan-500': data[column.key] === CustomerType.Cash,
								'tw3-bg-indigo-500': data[column.key] === CustomerType.Insurance
							}"
						>
							{{
								data[column.key] === CustomerType.Cash
									? 'Retail'
									: data[column.key]
							}}
						</div>
						<div
							v-else-if="
								column.key === 'tax_exempt' && data[column.key] === 'yes'
							"
							class="tw3-rounded tw3-text-white tw3-bg-gray-400 tw3-text-sm tw3-leading-3 tw3-shadow-md tw3-p-2"
						>
							Exempt
						</div>
						<div
							v-else-if="
								column.key === 'po_required' && data[column.key] === 'yes'
							"
							class="tw3-rounded tw3-text-white tw3-bg-sky-700 tw3-text-sm tw3-leading-3 tw3-shadow-md tw3-p-2"
						>
							PO Required
						</div>
					</div>
					<div
						v-else-if="column.key === 'notes'"
						v-tooltip.top="data[column.key]"
						class="tw3-whitespace-nowrap tw3-max-w-64 tw3-truncate"
					>
						{{ data[column.key] }}
					</div>
					<a
						v-else-if="column.key === 'email'"
						class="tw3-whitespace-nowrap"
						:href="'mailto:' + data[column.key]"
					>
						{{ data[column.key] }}
					</a>
					<a
						v-else-if="
							column.key === 'phone' || column.key === 'secondary_phone'
						"
						class="tw3-whitespace-nowrap"
						:href="'tel:' + data[column.key]"
					>
						{{ data[column.key] }}
					</a>
					<a
						v-else-if="column.key === 'full_address'"
						class="tw3-whitespace-nowrap"
						href="#"
						@click="emits('on-open-address', data['id'], data['g_address_id'])"
					>
						{{ data[column.key] }}
					</a>
					<div
						v-else
						class="tw3-whitespace-nowrap"
						:class="{
							'tw3-font-bold':
								column.key === 'name_first' || column.key === 'name_last'
						}"
					>
						{{ data[column.key] }}
					</div>
					<div
						v-if="
							!store.loadingMore &&
							index === store.customers.state.customers.length - 1 &&
							!store.preventFetchMore
						"
						v-infinite-scroll="store.fetchCustomersMore"
					/>
				</div>
			</template>
		</Column>

		<template v-if="!store.customers.isLoading" #empty>
			<div
				class="tw3-w-full tw3-h-full tw3-flex tw3-justify-center tw3-items-center"
			>
				<p class="tw3-text-center tw3-text-3xl">{{ t('noData') }}</p>
			</div>
		</template>
	</DataTable>
</template>

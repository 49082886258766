import { CustomersTableHeader } from '@/entities/customer/lib/types';

export const invoiceTerms = [
	{ id: 'dor', label: 'Due on receipt', name: 'Due on receipt', value: 'dor' },
	{ id: 'net10', label: 'Net 10', name: 'Net 10', value: 'net10' },
	{ id: 'net15', label: 'Net 15', name: 'Net 15', value: 'net15' },
	{ id: 'net20', label: 'Net 20', name: 'Net 20', value: 'net20' },
	{
		id: 'net20_500',
		label: 'Net 20 $500',
		name: 'Net 20 $500',
		value: 'net20_500'
	},
	{ id: 'net30', label: 'Net 30', name: 'Net 30', value: 'net30' },
	{ id: 'net45', label: 'Net 45', name: 'Net 45', value: 'net45' },
	{ id: 'net60', label: 'Net 60', name: 'Net 60', value: 'net60' },
	{ id: 'net90', label: 'Net 90', name: 'Net 90', value: 'net90' },
	{ id: 'custom', label: 'Custom', name: 'Custom', value: 'custom' }
];

export const edis = [
	{
		id: 'none',
		name: 'None'
	},
	{
		id: 'safelite',
		name: 'Safelite'
	},
	{
		id: 'lynx',
		name: 'Lynx ($1.25)'
	},
	{
		id: 'harmon',
		name: 'Harmon'
	},
	{
		id: 'quest',
		name: 'Quest (Under Maintenance)'
	},
	{
		id: 'gerber',
		name: 'Gerber ($1)'
	}
];

export const taxesHeader: CustomersTableHeader[] = [
	{ key: 'name', title: 'Name' },
	{ key: 'percentage', title: 'Percentage' },
	{ key: 'for_materials', title: 'Materials' },
	{ key: 'for_labor', title: 'Labor' }
];

export const vehiclesHeader: CustomersTableHeader[] = [
	{ key: 'vehicle', title: 'Vehicle' },
	{ key: 'vin', title: 'VIN' }
];

export const previousJobsHeader: CustomersTableHeader[] = [
	{ key: 'id', title: 'ID' },
	{ key: 'status', title: 'Status' },
	{ key: 'install_date', title: 'Install Date' },
	{ key: 'invoice_date', title: 'Invoice Date' },
	{ key: 'total_balance_after_payments', title: 'Balance' }
];

import { gql } from '@apollo/client/core';

export const SHOPS_ORGANIZATION_QUERY = gql`
	query getShopsByOrganization($organization_id: ID!) {
		shopsByOrganization(organization_id: $organization_id) {
			id
			name
		}
	}
`;

import { CORE_API } from '@/shared/api';

import {
	Csrs,
	Quote,
	QuoteRaw,
	QuoteRequestParams,
	QuoteResponse
} from './types';
import { normazileQuotes } from './utils';

export const CSRS_URL = '/unum/vendor/organization/gb_csr';
export const QUOTE_URL = '/unum/job-details/jobslist/quotes-dialog/table';
export const QUOTE_DATA_UPDATE_URL = '/unum/job-details/data';

export const getCsrs = async (id: number): Promise<Csrs[]> => {
	try {
		return (await CORE_API.get<Csrs[]>(`${CSRS_URL}/${id}`)).data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getMany = async (
	params: QuoteRequestParams
): Promise<QuoteResponse<Quote>> => {
	try {
		const response = await CORE_API.post<QuoteResponse<QuoteRaw>>(
			QUOTE_URL,
			params
		);

		return {
			numberOfRowsFound: response.data.numberOfRowsFound,
			rows: normazileQuotes(response.data.rows)
		};
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const update = async (
	id: string,
	follow_up_date: string | null
): Promise<void> => {
	try {
		await CORE_API.post(`${QUOTE_DATA_UPDATE_URL}/${id}`, { follow_up_date });
	} catch (error) {
		console.error(error);
		throw error;
	}
};

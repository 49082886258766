import { FilterMatchMode } from '@primevue/core/api';

import { i18n } from '@/shared/i18n';

import { Filters, FiltersWithMetadata, TableHeader } from './types';

const header: TableHeader[] = [
	{ key: 'jobId', title: i18n.global.t('jobId') },
	{ key: 'submittedDt', title: i18n.global.t('date') },
	{ key: 'customerName', title: i18n.global.t('customer') },
	{ key: 'vehicle', title: i18n.global.t('vehicle') },
	{ key: 'referenceNumber', title: i18n.global.t('reference') },
	{ key: 'status', title: i18n.global.t('status') }
];

export const tableHeaders: TableHeader[][] = [header];

export const statusOptions = [
	{
		label: i18n.global.t('capitalize', { key: 'coverage' }),
		value: 'CLAIM_STATUS_COVERAGE'
	},
	{
		label: i18n.global.t('capitalize', { key: 'new' }),
		value: 'CLAIM_STATUS_NEW'
	},
	{
		label: i18n.global.t('capitalize', { key: 'error' }),
		value: 'CLAIM_STATUS_ERROR'
	},
	{
		label: i18n.global.t('capitalize', { key: 'pending' }),
		value: 'CLAIM_STATUS_PENDING'
	},
	{
		label: i18n.global.t('capitalize', { key: 'success' }),
		value: 'CLAIM_STATUS_SUCCESS'
	}
];

export const archiveOptions = [
	{ label: i18n.global.t('showActive'), value: false },
	{ label: i18n.global.t('showArchive'), value: true },
	{ label: i18n.global.t('all'), value: 'all' }
];

export const filtersInit: FiltersWithMetadata<Filters> = {
	archived: { matchMode: FilterMatchMode.EQUALS, value: false },
	customerName: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	id: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	jobId: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	referenceNumber: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	status: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	submittedDt: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	vehicle: { matchMode: FilterMatchMode.CONTAINS, value: '' }
};

export const params = {
	archived: false,
	dateSubmitted: '',
	firstName: '',
	internalId: '',
	jobId: '',
	lastName: '',
	limit: 10,
	order: true,
	page: 1,
	referenceNumber: '',
	response: '',
	sort: 'internalId',
	status: ''
};

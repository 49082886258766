import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed } from 'vue';

import { OrganizationShortInfo } from '@/entities/organizations/lib/types';

import { getOne } from './api';

export const useUserStore = defineStore('user', () => {
	const userState = useAsyncState(
		() => {
			return getOne();
		},
		{
			user: {
				id: 0,
				shop_id: 0
			},
			shops: [],
			organizations: []
		},
		{
			immediate: true,
			shallow: false
		}
	);

	const defaultOrganization = computed(() => {
		const defaultShop = userState.state.value.shops.find(
			(shop: any) => shop.id === userState.state.value.user.shop_id
		);
		const res = userState.state.value.organizations?.find(
			(organization: OrganizationShortInfo) =>
				organization.id == defaultShop?.organization_id
		);
		return res;
	});

	return {
		user: userState.state,
		loadUser: userState.execute,
		defaultOrganization
	};
});

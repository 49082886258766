<script setup lang="ts">
import dayjs from 'dayjs';
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Fieldset from 'primevue/fieldset';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import Popover from 'primevue/popover';
import Select from 'primevue/select';
import ToggleButton from 'primevue/togglebutton';
import { computed, defineModel, defineProps, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { AdditionalInfo, Agent } from '@/entities/claim/lib/types';
import { isEmptyValue } from '@/shared/helpers';

import { insuranceClaimCodes } from './lib/constants';
import { calculateSubrogationData } from './lib/utils';

const { t } = useI18n();

const additionalInfo = defineModel<AdditionalInfo>('additionalInfo', {
	required: true
});

const agent = defineModel<Agent>('agent', {
	required: true
});

const { dateOfLoss } = defineProps<{
	dateOfLoss: string;
	status: boolean;
}>();

const popoverRef = ref();
const formattedDateOfLoss = dayjs(dateOfLoss).format('YYYY-MM-DD');

const computedCauseOfLossFields = computed(() =>
	calculateSubrogationData(additionalInfo.value.causeOfLossCode)
);

const toggleAdditionalInfoSettings = event => {
	popoverRef.value.toggle(event);
};

watch(computedCauseOfLossFields, newCauseOfLossFields => {
	if (newCauseOfLossFields.SubrogationContactName === null) {
		additionalInfo.value.subrogationContactName = '';
	}
	if (newCauseOfLossFields.SubrogationData === null) {
		additionalInfo.value.subrogationData = '';
	}
});
</script>

<template>
	<Fieldset
		class="tw3-relative"
		:legend="`${t('incident')} ${formattedDateOfLoss}`"
		toggleable
	>
		<div
			class="tw3-h-10 tw3-w-10 tw3-flex tw3-absolute tw3--top-10 tw3-right-6 tw3-bg-surface-50 tw3-border-surface-200 tw3-rounded-md tw3-border"
		>
			<Button
				aria-label="Settings"
				class="tw3-border-surface-200"
				icon="pi pi-cog"
				outlined
				severity="secondary"
				text
				type="button"
				@click="toggleAdditionalInfoSettings"
			/>

			<Popover ref="popoverRef">
				<div>
					<div class="tw3-grid tw3-grid-cols-2 tw3-gap-4">
						<div>
							<div
								class="tw3-h-8 tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-content-end"
							>
								{{ t('agencyName') }}
							</div>
							<InputText
								v-model="agent.agencyName"
								:disabled="status"
								type="text"
							/>
						</div>

						<div>
							<div
								class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
							>
								{{ t('contactPhone') }}
							</div>
							<InputMask
								id="phone"
								v-model="agent.phone"
								:disabled="status"
								mask="(999) 999-9999"
								placeholder="(999) 999-9999"
							/>
						</div>

						<div>
							<div
								class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
							>
								{{ t('agentFirstName') }}
							</div>
							<InputText
								v-model="agent.firstName"
								:disabled="status"
								type="text"
							/>
						</div>

						<div>
							<div
								class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
							>
								{{ t('agentFirstName') }}
							</div>
							<InputText
								v-model="agent.lastName"
								:disabled="status"
								type="text"
							/>
						</div>
					</div>
				</div>
			</Popover>
		</div>

		<div class="tw3-grid tw3-grid-cols-2 tw3-basis-2/3 tw3-gap-2">
			<div>
				<div
					class="tw3-h-8 tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-content-end"
				>
					<span v-tooltip.top="t('causeOfLossTooltip')">{{
						t('causeOfLoss')
					}}</span>
				</div>
				<Select
					v-model="additionalInfo.causeOfLossCode"
					class="tw3-w-full tw3-max-w-64"
					:disabled="status"
					optionLabel="title"
					:options="insuranceClaimCodes"
					optionValue="value"
				/>
			</div>

			<div>
				<div
					class="tw3-h-8 tw3-relative tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
				>
					<span v-tooltip.top="t('shopContactPersonTooltip')">{{
						t('shopContactPerson')
					}}</span>
					<Badge
						v-if="isEmptyValue(additionalInfo.originationContactName)"
						v-tooltip.top="t('required')"
						class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
						severity="danger"
					/>
				</div>
				<InputText
					v-model="additionalInfo.originationContactName"
					class="tw3-w-full tw3-max-w-64"
					:disabled="status"
					type="text"
				/>
			</div>

			<div>
				<div
					class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
				>
					{{ t('subrogationData') }}
					<span class="tw3-text-slate-400 tw3-text-xs">{{
						computedCauseOfLossFields.SubrogationData
					}}</span>
				</div>
				<InputText
					v-model="additionalInfo.subrogationData"
					class="tw3-w-full tw3-max-w-64"
					:disabled="
						isEmptyValue(computedCauseOfLossFields.SubrogationData) || status
					"
					type="text"
				/>
			</div>

			<div>
				<div
					class="tw3-h-8 tw3-relative tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-content-end"
				>
					<span v-tooltip.top="t('glassOnlyLossTooltip')">{{
						t('glassOnlyLoss')
					}}</span>
					<Badge
						v-if="isEmptyValue(additionalInfo.glassOnlyDamage)"
						v-tooltip.top="t('required')"
						class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
						severity="danger"
					/>
				</div>
				<ToggleButton
					v-model="additionalInfo.glassOnlyDamage"
					:disabled="status"
					:offLabel="t('no')"
					:onLabel="t('yes')"
				/>
			</div>

			<div>
				<div
					class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
				>
					{{ computedCauseOfLossFields.SubrogationContactName ?? t('details') }}
				</div>
				<InputText
					v-model="additionalInfo.subrogationContactName"
					class="tw3-w-full tw3-max-w-64"
					:disabled="
						isEmptyValue(computedCauseOfLossFields.SubrogationContactName) ||
						status
					"
					type="text"
				/>
			</div>

			<div>
				<div
					class="tw3-h-8 tw3-relative tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
				>
					<span v-tooltip.top="t('bodyInjuryTooltip')">{{
						t('bodyInjury')
					}}</span>
					<Badge
						v-if="isEmptyValue(additionalInfo.bodilyInjury)"
						v-tooltip.top="t('required')"
						class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
						severity="danger"
					/>
				</div>
				<ToggleButton
					v-model="additionalInfo.bodilyInjury"
					:disabled="status"
					:offLabel="t('no')"
					:onLabel="t('yes')"
				/>
			</div>
		</div>
	</Fieldset>
</template>

<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { computed, defineModel } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouterLink } from 'vue-router';

import ClaimStatus from '@/entities/claim/ClaimStatus.vue';
import { useClaimDetailsStore } from '@/entities/claim/lib/store';
import { ClaimStatus as ClaimStatusEnum } from '@/entities/claim/lib/types';
import AdditionalInfo from '@/features/additionalInfo/AdditionalInfo.vue';
import CoverageResponse from '@/features/coverageResponse/CoverageResponse.vue';
import DamageInfo from '@/features/damageInfo/DamageInfo.vue';
import VehicleInfo from '@/features/vehicleInfo/VehicleInfo.vue';
import { AutoAccept } from '@/shared/ui';
import InsuredCustomer from '@/widgets/insurance/InsuredCustomer.vue';

const { t } = useI18n();

const confirm = useConfirm();
const toast = useToast();

const claimDetailsStore = useClaimDetailsStore();
const showModal = defineModel<boolean>('show');

const showCoverageResponce = computed(
	() =>
		claimDetailsStore.claim.state.result.status ===
			ClaimStatusEnum.CLAIM_STATUS_COVERAGE ||
		claimDetailsStore.claim.state.result.status ===
			ClaimStatusEnum.CLAIM_STATUS_SUCCESS ||
		claimDetailsStore.claim.state.result.status ===
			ClaimStatusEnum.CLAIM_STATUS_ERROR
);

const isStatusErrorOrPending = computed(
	() =>
		claimDetailsStore.claim.state.result.status ===
			ClaimStatusEnum.CLAIM_STATUS_ERROR ||
		claimDetailsStore.claim.state.result.status ===
			ClaimStatusEnum.CLAIM_STATUS_PENDING
);

const shouldBeBlocked = computed(
	() =>
		claimDetailsStore.claim.state.result.status ===
			ClaimStatusEnum.CLAIM_STATUS_SUCCESS ||
		claimDetailsStore.claim.state.result.status ===
			ClaimStatusEnum.CLAIM_STATUS_COVERAGE ||
		claimDetailsStore.claim.state.result.status ===
			ClaimStatusEnum.CLAIM_STATUS_ERROR
);

const isStatusNew = computed(
	() =>
		claimDetailsStore.claim.state.result.status ===
		ClaimStatusEnum.CLAIM_STATUS_NEW
);

const handleSendClaim = async () => {
	if (claimDetailsStore.hasEmptyFields) {
		toast.add({
			detail: 'Please fill in all required fields.',
			life: 3000,
			severity: 'warn',
			summary: 'Warn Message'
		});

		return;
	}

	confirm.require({
		accept: async () => {
			await claimDetailsStore.createClaim({
				acknowledgementDetails:
					claimDetailsStore.claim.state.acknowledgementDetails,
				additionalInfo: claimDetailsStore.claim.state.additionalInfo,
				agent: claimDetailsStore.claim.state.agent,
				customer: claimDetailsStore.claim.state.customer,
				damageInfo: claimDetailsStore.claim.state.damageInfo,
				insurance: claimDetailsStore.claim.state.insurance,
				jobId: claimDetailsStore.claim.state.jobId,
				providerName: claimDetailsStore.claim.state.providerName,
				vehicle: claimDetailsStore.claim.state.vehicle
			});
		},
		acceptLabel: t('yes'),
		acceptProps: {
			severity: 'success'
		},
		header: t('submitClaim'),
		message: t('submitClaimMsg'),
		rejectLabel: t('no'),
		rejectProps: {
			severity: 'secondary'
		}
	});
};
</script>

<template>
	<Dialog
		id="vue3ClaimDialog"
		v-model:visible="showModal"
		appendTo="#vue3app"
		class="tw3-w-full tw3-max-w-[990px]"
		modal
	>
		<template #header>
			<div class="tw3-flex tw3-items-center tw3-text-xl">
				<span class="tw3-font-bold">{{ t('claimForJob') }} #</span>
				<RouterLink
					class="tw3-ml-1"
					:to="`/jobs/${claimDetailsStore.claim.state.jobId}`"
					>{{ claimDetailsStore.claim.state.jobId }}</RouterLink
				>
				<ClaimStatus
					class="tw3-ml-2"
					:status="claimDetailsStore.claim.state.result.status"
				/>
			</div>
		</template>
		<div class="tw3-grid tw3-grid-cols-3 tw3-gap-4 tw3-mb-4 tw3-pt-2">
			<CoverageResponse
				v-if="showCoverageResponce"
				class="tw3-col-span-3"
				:deductible="
					claimDetailsStore.claim.state.result.coverageResponseVehicle
						.deductible
				"
				:errorMessage="claimDetailsStore.claim.state.result.errorMessage"
				:referenceNumber="claimDetailsStore.claim.state.result.referenceNumber"
			/>
			<InsuredCustomer
				v-model:insurance="claimDetailsStore.claim.state.insurance"
				v-model:insuredCustomer="claimDetailsStore.claim.state.customer"
				class="tw3-col-span-2"
				:policyNumber="claimDetailsStore.claim.state.insurance.policyNumber"
				:status="shouldBeBlocked"
			/>
			<VehicleInfo
				v-model:vehicle="claimDetailsStore.claim.state.vehicle"
				:status="shouldBeBlocked"
			/>
			<AdditionalInfo
				v-model:additionalInfo="claimDetailsStore.claim.state.additionalInfo"
				v-model:agent="claimDetailsStore.claim.state.agent"
				v-model:insurance="claimDetailsStore.claim.state.insurance"
				class="tw3-col-span-2"
				:collapsed="showCoverageResponce"
				:dateOfLoss="claimDetailsStore.claim.state.insurance.dateOfLoss"
				:status="shouldBeBlocked"
			/>
			<AutoAccept
				v-model:acknowledgement-details="
					claimDetailsStore.claim.state.acknowledgementDetails
				"
				:collapsed="showCoverageResponce"
				:status="shouldBeBlocked"
			/>
			<DamageInfo
				v-model:damageInfoData="claimDetailsStore.claim.state.damageInfo"
				:addClaimDamageInfo="claimDetailsStore.addClaimDamageInfo"
				class="mb-3 tw3-col-span-3"
				:collapsed="showCoverageResponce"
				:removeClaimDamageInfo="claimDetailsStore.removeClaimDamageInfo"
				:status="shouldBeBlocked"
			/>
		</div>

		<div class="tw3-flex tw3-justify-end">
			<Button
				v-if="!isStatusNew"
				label="Restart Claim"
				severity="secondary"
				@click="claimDetailsStore.restartClaim"
			/>
			<Button
				v-if="!showCoverageResponce"
				class="tw3-ml-5"
				:label="`${isStatusErrorOrPending ? 'Restart Claim' : 'Send Claim'}`"
				@click="handleSendClaim"
			/>
		</div>
	</Dialog>
</template>

import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import { i18n } from '@/shared/i18n';

import { storeReset } from './plugins';
import { usePrimeVue } from './primevue';
import router from './router';

const initApp = (app: any) => {
	const store = createPinia();
	store.use(piniaPluginPersistedstate);

	usePrimeVue(app);
	app.use(i18n);
	store.use(storeReset);
	app.use(store);
	app.use(router);
};

export default initApp;
export * from './router';
export * from './plugins';

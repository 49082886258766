import dayjs from 'dayjs';

import { Claim, ClaimRaw, ClaimStatus } from '../types';

export const normalizeClaims = (data: ClaimRaw[]): Claim[] => {
	return data.map(
		({
			archived,
			id,
			jobId,
			submittedDt,
			customerName,
			referenceNumber,
			vehicle,
			status
		}: ClaimRaw) => {
			const normalizeDate = dayjs(submittedDt).format('YYYY-MM-DD');

			return {
				archived,
				customerName,
				id,
				jobId,
				referenceNumber,
				status: ClaimStatus[status],
				submittedDt: normalizeDate,
				vehicle
			};
		}
	);
};

export const locationOfDamage = [
	{ label: 'Windshield Repair', value: 'OPENING_TYPE_CODE_WR' },
	{ label: 'Windshield', value: 'OPENING_TYPE_CODE_WS' },
	{ label: 'Door', value: 'OPENING_TYPE_CODE_DR' },
	{ label: 'Side', value: 'OPENING_TYPE_CODE_SI' },
	{ label: 'Vent', value: 'OPENING_TYPE_CODE_VN' },
	{ label: 'Quarter', value: 'OPENING_TYPE_CODE_QT' },
	{ label: 'Partition', value: 'OPENING_TYPE_CODE_PT' },
	{ label: 'Back Window', value: 'OPENING_TYPE_CODE_BK' },
	{ label: 'Roof', value: 'OPENING_TYPE_CODE_RF' }
];

export const locationOfDamageLables = {
	OPENING_TYPE_CODE_BK: 'Back Window',
	OPENING_TYPE_CODE_DR: 'Door',
	OPENING_TYPE_CODE_PT: 'Partition',
	OPENING_TYPE_CODE_QT: 'Quarter',
	OPENING_TYPE_CODE_RF: 'Roof',
	OPENING_TYPE_CODE_SI: 'Side',
	OPENING_TYPE_CODE_VN: 'Vent',
	OPENING_TYPE_CODE_WR: 'Windshield Repair',
	OPENING_TYPE_CODE_WS: 'Windshield'
};

export const nagsPositionCode = [
	{ label: 'Back', value: 'POSITION_CODE_B' },
	{ label: 'Front', value: 'POSITION_CODE_F' },
	{ label: 'Middle', value: 'POSITION_CODE_M' },
	{ label: 'Rear', value: 'POSITION_CODE_R' }
];

export const nagsPositionCodeLabels = {
	POSITION_CODE_B: 'Back',
	POSITION_CODE_F: 'Front',
	POSITION_CODE_M: 'Middle',
	POSITION_CODE_R: 'Rear'
};

export const nagsRelativeLocationCode = [
	{ label: 'Inner', value: 'RELATIVE_LOCATION_CODE_IN' },
	{ label: 'Lower', value: 'RELATIVE_LOCATION_CODE_LO' },
	{ label: 'Outer', value: 'RELATIVE_LOCATION_CODE_OU' },
	{ label: 'Upper', value: 'RELATIVE_LOCATION_CODE_UP' }
];

export const nagsRelativeLocationCodeLabels = {
	RELATIVE_LOCATION_CODE_IN: 'Inner',
	RELATIVE_LOCATION_CODE_LO: 'Lower',
	RELATIVE_LOCATION_CODE_OU: 'Outer',
	RELATIVE_LOCATION_CODE_UP: 'Upper'
};

export const nagsSideCode = [
	{ label: 'Left', value: 'SIDE_CODE_L' },
	{ label: 'Right', value: 'SIDE_CODE_R' },
	{ label: 'Center', value: 'SIDE_CODE_C' }
];

export const nagsSideCodeLabels = {
	SIDE_CODE_C: 'Center',
	SIDE_CODE_L: 'Left',
	SIDE_CODE_R: 'Right'
};

export const quantityOfGlassForLoss = [
	{ label: 1, value: 1 },
	{ label: 2, value: 2 },
	{ label: 3, value: 3 },
	{ label: 4, value: 4 },
	{ label: 5, value: 5 },
	{ label: 6, value: 6 },
	{ label: 7, value: 7 },
	{ label: 8, value: 8 },
	{ label: 9, value: 9 }
];
export const retailerQualificationOfRepairOption = [
	{ lable: 'Repairable', value: true },
	{ lable: 'Not Repairable', value: false }
];

import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';

import {
	getAmountAndRevenueOfInvoices,
	getAverageAndNumberOfInvoices,
	getFilteredAverageOfInvoices,
	getFilteredNumberOfCustomers,
	getFilteredNumberOfInvoices,
	getLatestInvoices,
	getRevenueAndInvoicesAmount,
	getTotalAndNumberOfCustomers,
	getTotalAndNumberOfInvoices
} from './api';
import { TimeRange } from './enums';
import { DashboardLifetimeObjectNumber, NewestInvoices } from './types';

export const useDashboardStore = defineStore('dashboard', () => {
	const newestInvoices = useAsyncState<NewestInvoices[]>(
		async () => {
			try {
				const result = await getLatestInvoices();
				return result;
			} catch (e) {
				return [];
			}
		},
		[],
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const revenueAndInvoicesAmount = useAsyncState(
		async (params: { shop_ids?: number[]; time_range: TimeRange }) => {
			try {
				const result = await getRevenueAndInvoicesAmount(params);
				return result;
			} catch (e) {
				return { invoices: [], revenue: [] };
			}
		},
		{ invoices: [], revenue: [] },
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const filteredAverageOfInvoices = useAsyncState(
		async (params: { shop_ids?: number[]; time_range: TimeRange }) => {
			try {
				const result = await getFilteredAverageOfInvoices(params);
				return result;
			} catch (e) {
				return [];
			}
		},
		[],
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const filteredNumberOfInvoices = useAsyncState(
		async (params: { shop_ids?: number[]; time_range: TimeRange }) => {
			try {
				const result = await getFilteredNumberOfInvoices(params);
				return result;
			} catch (e) {
				return [];
			}
		},
		[],
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const filteredNumberOfCustomers = useAsyncState(
		async (params: { shop_ids?: number[]; time_range: TimeRange }) => {
			try {
				const result = await getFilteredNumberOfCustomers(params);
				return result;
			} catch (e) {
				return [];
			}
		},
		[],
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const totalAndNumberOfInvoices = useAsyncState<DashboardLifetimeObjectNumber>(
		async () => {
			try {
				const result = await getTotalAndNumberOfInvoices();
				return { ...result, count: Number(result.count) };
			} catch (e) {
				return { data: [], count: 0, difference: 0 };
			}
		},
		{ data: [], count: 0, difference: 0 },
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const averageAndNumberOfInvoices =
		useAsyncState<DashboardLifetimeObjectNumber>(
			async () => {
				try {
					const result = await getAverageAndNumberOfInvoices();
					return result;
				} catch (e) {
					return { data: [], count: 0, difference: 0 };
				}
			},
			{ data: [], count: 0, difference: 0 },
			{
				immediate: false,
				resetOnExecute: false
			}
		);

	const amountAndRevenueOfInvoices =
		useAsyncState<DashboardLifetimeObjectNumber>(
			async () => {
				try {
					const result = await getAmountAndRevenueOfInvoices();
					return result;
				} catch (e) {
					return { data: [], count: 0, difference: 0 };
				}
			},
			{ data: [], count: 0, difference: 0 },
			{
				immediate: false,
				resetOnExecute: false
			}
		);

	const totalAndNumberOfCustomers =
		useAsyncState<DashboardLifetimeObjectNumber>(
			async () => {
				try {
					const result = await getTotalAndNumberOfCustomers();
					return { ...result, count: Number(result.count) };
				} catch (e) {
					return { data: [], count: 0, difference: 0 };
				}
			},
			{ data: [], count: 0, difference: 0 },
			{
				immediate: false,
				resetOnExecute: false
			}
		);

	return {
		newestInvoices,
		revenueAndInvoicesAmount,
		filteredAverageOfInvoices,
		filteredNumberOfInvoices,
		filteredNumberOfCustomers,
		totalAndNumberOfInvoices,
		averageAndNumberOfInvoices,
		amountAndRevenueOfInvoices,
		totalAndNumberOfCustomers
	};
});

<script setup lang="ts">
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import { useToast } from 'primevue/usetoast';
import { ref } from 'vue';

import { useCustomerEditStore } from '@/entities/customer/lib/store';
import { Customer } from '@/entities/customer/lib/types';
import { UPDATE_VEHICLE_MUTATION } from '@/entities/vehicle/lib/graphql/mutation/vehicle.mutation';
import useApolloClient from '@/shared/apollo';

import { vehiclesHeader } from '../../lib/constants';

const client = useApolloClient;
const toast = useToast();
const store = useCustomerEditStore();

const loading = ref(false);

const updateValue = (field: keyof Customer, value: any) => {
	store.customer.state = { ...store.customer.state, [field]: value };
};

const removeVehicle = async (id: number) => {
	loading.value = true;
	try {
		await client.mutate({
			mutation: UPDATE_VEHICLE_MUTATION,
			variables: { id, vehicle: { consumer_id: null } }
		});
		updateValue(
			'vehicles',
			store.customer.state.vehicles.filter(v => v.id !== id)
		);
	} catch (e) {
		toast.add({
			detail: 'Something went wrong',
			life: 3000,
			severity: 'error',
			summary: 'Error Message'
		});
	} finally {
		loading.value = false;
	}
};
</script>

<template>
	<DataTable
		class="tw3-w-full"
		:loading="store.customer.isLoading"
		:value="store.customer.state.vehicles"
	>
		<template #empty>
			<div class="tw3-text-center">No Data</div>
		</template>
		<Column
			v-for="column of vehiclesHeader"
			:key="column.key"
			:field="column.key"
			:header="column.title"
		>
			<template #body="{ data }">
				<div v-if="column.key === 'vehicle'" class="tw3-flex tw3-flex-col">
					<div>{{ data.year + ' ' + data.make + ' ' + data.model }}</div>
					<div class="tw3-text-xs">{{ data.style }}</div>
				</div>
				<span v-else class="tw3-tracking-widest">{{ data[column.key] }}</span>
			</template>
		</Column>
		<Column>
			<template #body="{ data }">
				<div
					class="tw3-flex tw3-flex-col tw3-items-center tw3-justify-center tw3-border-b-0"
				>
					<Button
						aria-label="Delete"
						class="tw3-w-8 tw3-h-8"
						icon="pi pi-trash"
						:loading="loading"
						rounded
						severity="danger"
						text
						@click="removeVehicle(data.id)"
					/>
				</div>
			</template>
		</Column>
	</DataTable>
</template>

<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import ToggleSwitch from 'primevue/toggleswitch';
import { useFieldArray, useForm } from 'vee-validate';
import { nextTick, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import * as zod from 'zod';

import { useCustomerEditStore } from '@/entities/customer/lib/store';
import { CustomerName } from '@/entities/customer/lib/types';
import { InputTextWithError } from '@/shared/ui';

const ZOD_NAMES = 'names';

const props = defineProps<{
	modelValue: CustomerName[];
}>();

const emit = defineEmits<{
	(e: 'update:modelValue', c: CustomerName[]): void;
}>();

const { t } = useI18n();

const store = useCustomerEditStore();

const validationSchema = toTypedSchema(
	zod.object({
		[ZOD_NAMES]: zod.array(
			zod.object({
				first: zod.string({ message: t('required') }).min(1, t('required')),
				last: zod.string().nullable(),
				middle: zod.string().nullable()
			})
		)
	})
);

const { errors, setValues } = useForm({
	initialValues: { [ZOD_NAMES]: props.modelValue },
	validationSchema
});

const { fields, update } = useFieldArray<Omit<CustomerName, 'id'>>(ZOD_NAMES);

const addName = () => {
	emit('update:modelValue', [
		...props.modelValue,
		{ first: null, last: null, middle: null } as CustomerName
	]);
	if (props.modelValue.length) return;
	store.primaryNameIndex = 0;
};

const updateVal = async (
	index: number,
	field: keyof CustomerName,
	val: string | null
) => {
	const input = document.getElementById(
		`names${field}${index}`
	) as HTMLInputElement;
	const cursorPosition = input.selectionStart;
	const newVal = val
		? val
				.split(' ')
				.map(word => word.charAt(0).toUpperCase() + word.slice(1))
				.join(' ')
		: val;
	emit(
		'update:modelValue',
		props.modelValue.map((v, vIndex) => {
			if (vIndex === index) v[field] = newVal as never;
			return v;
		})
	);
	update(index, { ...fields.value[index].value, [field]: newVal });
	await nextTick();
	input.setSelectionRange(cursorPosition, cursorPosition);
};

const removeVal = (index: number) => {
	emit(
		'update:modelValue',
		props.modelValue.filter((_, i) => i !== index)
	);
	if (store.primaryNameIndex < index) return;
	if (store.primaryNameIndex === index) {
		store.primaryNameIndex = 0;
	} else if (store.primaryNameIndex > index) {
		store.primaryNameIndex -= 1;
	}
};

watch(
	() => props.modelValue,
	() => {
		setValues({ [ZOD_NAMES]: props.modelValue });
	}
);

watch(
	() => errors.value,
	() => store.updateErrors('names', errors.value)
);
</script>

<template>
	<DataTable
		class="ui-data-table"
		size="small"
		stripedRows
		tableStyle="tw3-w-full"
		:value="fields"
	>
		<Column
			field="first"
			header="First name"
			:pt="{
				headerCell: {
					style: {
						width: '33.3%'
					}
				}
			}"
		>
			<template #body="{ index }">
				<InputTextWithError
					:id="`namesfirst${index}`"
					class="tw3-w-full tw3-max-w-64"
					:errMsg="errors[`names[${index}].first` as any]"
					:modelValue="fields[index].value.first"
					placeholder="First Name"
					@update:model-value="v => updateVal(index, 'first', v)"
				/>
			</template>
		</Column>

		<Column
			field="last"
			header="Last name"
			:pt="{
				headerCell: {
					style: {
						width: '33.3%'
					}
				}
			}"
		>
			<template #body="{ index }">
				<InputTextWithError
					:id="`nameslast${index}`"
					class="tw3-w-full tw3-max-w-64"
					:errMsg="errors[`names[${index}].last` as any]"
					:modelValue="fields[index].value.last"
					placeholder="Last Name"
					@update:model-value="v => updateVal(index, 'last', v)"
				/>
			</template>
		</Column>

		<Column field="primary" header="Primary" style="width: 2.25rem">
			<template #body="{ index }">
				<div class="cell-container">
					<ToggleSwitch
						v-tooltip.top="'Set to primary'"
						:modelValue="store.primaryNameIndex === index"
						name="dynamic"
						:style="fields.length === 1 && 'opacity: 0.6'"
						@update:model-value="
							val =>
								val &&
								index !== store.primaryNameIndex &&
								(store.primaryNameIndex = index)
						"
					/>
				</div>
			</template>
		</Column>

		<Column field="actions" header="" style="width: 5%">
			<template #header>
				<div class="tw3-flex">
					<Button
						aria-label="Settings"
						icon="pi pi-plus"
						rounded
						size="small"
						text
						type="button"
						@click="addName"
					/>
				</div>
			</template>

			<template #body="{ index }">
				<div
					class="tw3-flex tw3-flex-col tw3-items-center tw3-justify-center tw3-border-b-0"
				>
					<Button
						aria-label="Delete"
						class="tw3-w-8 tw3-h-8"
						icon="pi pi-trash"
						rounded
						severity="danger"
						text
						@click="removeVal(index)"
					/>
				</div>
			</template>
		</Column>
	</DataTable>
</template>

<style lang="scss" scoped>
.ui-data-table *:not(input) {
	border-width: 0px !important;
}
</style>

import { gql } from '@apollo/client/core';

export const TAX_FRAGMENT = gql`
	fragment TaxFields on Tax {
		id
		name
		percentage
		for_labor
		for_materials
		default_tax
		status
		tax_zip_codes {
			zip_code
		}
		flat
		mobile_only
	}
`;

export const JOBTAX_FRAGMENT = gql`
	fragment JobtaxFields on Jobtax {
		id
		coa_update_trigger
		amount
		tax_co_account_id
		labor @client
		materials @client
		shop_tax_id
		tax {
			...TaxFields
		}
	}
	${TAX_FRAGMENT}
`;

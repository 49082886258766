import { Quote, QuoteRaw } from './types';

export const normazileQuotes = (data: QuoteRaw[]): Quote[] => {
	return data.map((quote: QuoteRaw) => ({
		amount: quote.amount,
		customerName: quote.customer_name,
		customerPhone: quote.customer_phone,
		followupDate: quote.f_followup_date,
		gbCsrId: quote.csr,
		id: quote.id,
		quoteDate: quote.f_quote_date,
		vehicle: quote.vehicle_full
	}));
};

<script setup lang="ts">
import Divider from 'primevue/divider';

import { useCustomerEditStore } from '@/entities/customer/lib/store';
import { Customer } from '@/entities/customer/lib/types';

import ContactInfoAddresses from './ContactInfoAddresses.vue';
import ContactInfoCompany from './ContactInfoCompany.vue';
import ContactInfoEmails from './ContactInfoEmails.vue';
import ContactInfoNames from './ContactInfoNames.vue';
import ContactInfoPhones from './ContactInfoPhones.vue';

const store = useCustomerEditStore();

const updateValue = (field: keyof Customer, value: any) => {
	store.customer.state = { ...store.customer.state, [field]: value };
};
</script>

<template>
	<div class="tw3-flex tw3-flex-col tw3-gap-5">
		<ContactInfoCompany
			v-if="
				store.customer.state.is_commercial || store.customer.state.is_insurance
			"
			:companyName="store.customer.state.company_name"
			:fax="store.customer.state.fax"
			:isCommercial="
				store.customer.state.is_commercial || store.customer.state.is_insurance
			"
			:website="store.customer.state.website"
			@update:company-name="v => updateValue('company_name', v)"
			@update:fax="v => updateValue('fax', v)"
			@update:is-commercial="v => updateValue('is_commercial', v)"
			@update:website="v => updateValue('website', v)"
		/>
		<Divider
			v-if="
				store.customer.state.is_commercial || store.customer.state.is_insurance
			"
			style="margin: 0"
		/>
		<ContactInfoNames
			:modelValue="store.customer.state.names"
			@update:model-value="v => updateValue('names', v)"
		/>
		<Divider style="margin: 0" />
		<ContactInfoPhones
			class="tw3-w-full"
			:modelValue="store.customer.state.phones"
			@update:model-value="v => updateValue('phones', v)"
		/>
		<Divider style="margin: 0" />
		<ContactInfoEmails
			class="tw3-w-full"
			:modelValue="store.customer.state.emails"
			@update:model-value="v => updateValue('emails', v)"
		/>
		<Divider style="margin: 0" />
		<ContactInfoAddresses
			:modelValue="store.customer.state.addresses"
			@update:model-value="v => updateValue('addresses', v)"
		/>
	</div>
</template>

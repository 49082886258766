import { avatarColorMap, avatarDefaultColor } from './contants';
import { Customer, CustomerName } from './types';

export const getConsumerName = (
	consumer: Pick<Customer, 'company_name' | 'name'>
) => {
	if (consumer.company_name) {
		return consumer.company_name;
	} else if (consumer.name) {
		const { first = '', last = '' } = consumer.name;
		if (first === null) return '-';
		return `${first} ${last || ''}`.toUpperCase();
	} else {
		return '-';
	}
};

export const getAvatarBg = (text?: string | null) => {
	if (!text) {
		return avatarDefaultColor;
	}

	const firstChar = text.trim().charAt(0).toLowerCase();

	return avatarColorMap[firstChar] || avatarDefaultColor;
};

export const getInitials = (
	customer: Pick<
		Customer,
		'is_commercial' | 'is_insurance' | 'company_name' | 'name'
	>
) => {
	const getCompanyInitials = (companyName: string | null) => {
		if (!companyName) return '';
		const parts = companyName.split(' ').slice(0, 2);
		return parts.map(part => part.charAt(0)).join('');
	};

	const getNameInitials = (name: CustomerName | null) => {
		const first = name?.first ?? '';
		const last = name?.last ?? '';
		return (first.charAt(0) + last.charAt(0)).toUpperCase();
	};

	if (customer.is_commercial || customer.is_insurance) {
		return getCompanyInitials(customer.company_name);
	} else {
		return getNameInitials(customer.name);
	}
};

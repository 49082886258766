<script setup lang="ts">
import { vInfiniteScroll } from '@vueuse/components';
import { debounce } from 'lodash-es';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';
import { defineModel, onMounted, ref } from 'vue';

import { useCustomerEditStore } from '@/entities/customer/lib/store';
import { Option } from '@/entities/customer/lib/types';

const emit = defineEmits<{
	(e: 'update-bill', c: Option): void;
}>();

const store = useCustomerEditStore();
const search = ref('');

const reFetch = debounce(() => {
	store.billsTo.execute(0, { first: 0, search: search.value });
}, 300);

const showModal = defineModel<boolean>('show');

const selectItem = (c: Option) => {
	emit('update-bill', { full_name: c.full_name, id: c.id });
	showModal.value = false;
};

onMounted(() => store.billsTo.execute(0, { first: 0, search: search.value }));
</script>

<template>
	<Dialog
		id="vue3UpdateBillTo"
		v-model:visible="showModal"
		appendTo="#vue3app"
		class="tw3-w-full tw3-max-w-lg"
		:contentStyle="{ padding: '1rem' }"
		contentClass="beautiful-scroll"
		header="Select Bill To"
		modal
	>
		<div class="tw3-w-full tw3-flex tw3-flex-col tw3-gap-6">
			<InputText
				v-model="search"
				class="tw3-w-full"
				placeholder="Bill To"
				type="text"
				@update:model-value="reFetch"
			/>
			<div
				class="tw3-flex tw3-flex-col tw3-gap-3 tw3-h-[452px] tw3-overflow-y-auto"
			>
				<div
					v-if="store.billsTo.isLoading"
					class="tw3-flex tw3-flex-1 tw3-justify-center tw3-items-center"
				>
					<ProgressSpinner
						aria-label="Loading"
						class="tw3-fill-sky-500"
						fill="transparent"
						style="width: 50px; height: 50px"
					/>
				</div>
				<template v-else>
					<div
						v-for="item in store.billsTo.state.rows"
						:key="item.id"
						class="tw3-p-2 tw3-rounded-lg tw3-cursor-pointer hover:tw3-bg-gray-200"
						@click="() => selectItem(item)"
					>
						<div class="tw3-text-slate-600">{{ item.full_name }}</div>
						<div class="tw3-text-xs tw3-text-slate-400">
							{{ item.trading_partner }}
						</div>
					</div>
					<div
						v-if="store.billsTo.state.rows.length !== store.billsTo.state.count"
						v-infinite-scroll="
							() => store.fetchBillMore(store.billsTo.state.rows.length, search)
						"
					>
						Loading more...
					</div>
					<div v-if="store.billsTo.state.count === 0">No data</div>
				</template>
			</div>
		</div>
	</Dialog>
</template>

import {
	GoogleAddress,
	GoogleAddressComponent,
	GoogleAddressComponents,
	GoogleAutocompleteResult,
	GoogleParsedResults
} from './types';

export const serializeAddressComponents = (data: GoogleAddress) => {
	const addressComponents: GoogleAddressComponents = (
		data.address_components || []
	).reduce(
		(
			aggr: GoogleAddressComponents,
			{ short_name, types }: GoogleAddressComponent
		) => {
			if (types[0]) {
				aggr[types[0]] = short_name;
			}
			return aggr;
		},
		{}
	);

	const address = ['street_number', 'route']
		.map(key => addressComponents[key])
		.filter(Boolean)
		.join(' ');

	const fullAddress = (data.formatted_address || '')
		.split(',')
		.slice(0, -1)
		.join(',');

	return {
		address,
		city: addressComponents.locality,
		full_address: fullAddress,
		google_place_id: data.place_id || '',
		lat: data.geometry?.location.lat() || 0,
		lng: data.geometry?.location.lng() || 0,
		state: addressComponents.administrative_area_level_1 || '',
		zip: addressComponents.postal_code || ''
	};
};

export const parseAutocompleteResults = (
	results: GoogleAutocompleteResult[]
): GoogleParsedResults[] => {
	return results.map(({ place_id, structured_formatting }) => ({
		place_id,
		...structured_formatting
	}));
};

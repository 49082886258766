<script setup lang="ts">
import InputText from 'primevue/inputtext';
import { InputTypeHTMLAttribute } from 'vue';

withDefaults(
	defineProps<{
		type?: InputTypeHTMLAttribute;
		modelValue?: string | null;
		placeholder?: string;
		errMsg?: string;
		disabled?: boolean;
		textInputStyle?: string;
	}>(),
	{
		errMsg: undefined,
		modelValue: undefined,
		placeholder: undefined,
		textInputStyle: undefined,
		type: 'text'
	}
);

const emit = defineEmits<{
	(e: 'update:modelValue', c: string | null): void;
}>();
</script>

<template>
	<InputText
		v-tooltip.top="{
			value: errMsg,
			pt: {
				text: {
					style: { backgroundColor: 'rgb(248 113 113)' }
				},
				arrow: {
					style: { borderTopColor: 'rgb(248 113 113)' }
				}
			}
		}"
		class="tw3-w-full"
		:disabled="disabled"
		:invalid="!!errMsg"
		:modelValue="modelValue"
		:placeholder="placeholder"
		:style="textInputStyle"
		:type="type"
		@update:model-value="v => emit('update:modelValue', v ?? null)"
	/>
</template>

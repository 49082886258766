<script setup lang="ts">
import Dialog from 'primevue/dialog';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import TabPanel from 'primevue/tabpanel';
import Tabs from 'primevue/tabs';
import { defineModel } from 'vue';
import { useI18n } from 'vue-i18n';

import ClaimsTable from '@/widgets/claimTable/ClaimsTable.vue';

const { t } = useI18n();

const showModal = defineModel<boolean>('show');
</script>

<template>
	<Dialog
		id="vue3InsuranceDialog"
		v-model:visible="showModal"
		appendTo="#vue3app"
		class="tw3-w-full tw3-max-w-[1400px]"
		:header="`Glaxis ${t('inbox')}`"
		modal
	>
		<Tabs value="0">
			<TabList>
				<Tab value="0"> {{ t('claims') }} </Tab>
			</TabList>
			<TabPanel value="0">
				<ClaimsTable />
			</TabPanel>
		</Tabs>
	</Dialog>
</template>

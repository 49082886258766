import { CORE_API } from '@/shared/api';

import { UserInfo } from './types';

const USER_URL = '/api/user';

export const getOne = async (): Promise<UserInfo> => {
	try {
		const response = await CORE_API.get(`${USER_URL}`);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

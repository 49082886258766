import { gql } from '@apollo/client/core';

import { RATES_FRAGMENT } from '@/shared/graphql/fragments/rates.fragment';
import { TAX_FRAGMENT } from '@/shared/graphql/fragments/tax.fragment';

export const SHOP_PIECES_QUERIES = {
	pricelevels: gql`
		query shopPricelevels($shop_id: ID!) {
			shopPricelevels(shop_id: $shop_id) {
				id
				name
			}
		}
	`,
	rates: gql`
		query shopRates($shop_id: ID!) {
			shopRates(shop_id: $shop_id) {
				...RatesFields
			}
		}
		${RATES_FRAGMENT}
	`,
	tax: gql`
		query shopTaxes($shop_id: ID!, $disabled: Boolean) {
			shopTaxes(shop_id: $shop_id, disabled: $disabled) {
				...TaxFields
			}
		}
		${TAX_FRAGMENT}
	`
};

import { FilterMatchMode } from '@primevue/core/api';

import { Quote } from '@/entities/quote/lib/types';
import { i18n } from '@/shared/i18n';

import { FiltersWithMetadata, TableHeader } from './types';

export const header: TableHeader[] = [
	{ key: 'id', title: i18n.global.t('quote') },
	{ key: 'customerName', title: i18n.global.t('customer') },
	{ key: 'customerPhone', title: i18n.global.t('phone') },
	{ key: 'vehicle', title: i18n.global.t('vehicle') },
	{ key: 'gbCsrId', title: i18n.global.t('csr') },
	{ key: 'followupDate', title: i18n.global.t('followUp') },
	{ key: 'quoteDate', title: i18n.global.t('date') },
	{ key: 'amount', title: i18n.global.t('amount') }
];

export const filtersInit: FiltersWithMetadata<Quote> = {
	amount: { matchMode: FilterMatchMode.CONTAINS, value: null },
	customerName: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	customerPhone: { matchMode: FilterMatchMode.CONTAINS, value: null },
	followupDate: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	gbCsrId: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	id: { matchMode: FilterMatchMode.CONTAINS, value: null },
	quoteDate: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	vehicle: { matchMode: FilterMatchMode.CONTAINS, value: '' }
};

import {
	any,
	anyPass,
	equals,
	flatten,
	isNil,
	map,
	pick,
	pipe,
	values
} from 'ramda';

export const isEmptyValue: (value: any) => boolean = anyPass([
	isNil,
	equals('')
]);

export const checkFieldsEmpty = (
	fieldsToCheck: string[],
	...forms: Record<string, any>[]
): boolean => {
	const getValuesFromForm = (form: Record<string, any>): any[] =>
		values(pick(fieldsToCheck, form));

	const getAllValues = (forms: Record<string, any>[]): any[] =>
		pipe(map(getValuesFromForm), flatten)(forms);

	const areFieldsEmpty = (values: any[]): boolean => any(isEmptyValue, values);

	return areFieldsEmpty(getAllValues(forms));
};

import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed } from 'vue';

import { checkFieldsEmpty } from '@/shared/helpers';

import { create, getOne } from '../api';
import { defaultClaimDetailsState, fieldsToCheck } from '../constants';
import {
	ClaimStatus,
	CreateClaimRequest,
	OpeningTypeCode,
	PositionCode,
	RelativeLocationCode,
	SideCode
} from '../types';

export const useClaimDetailsStore = defineStore('claimDetails', () => {
	const claim = useAsyncState(
		(args: any) => {
			return getOne({
				id: args.id
			}).then(data => {
				//@ts-ignore
				data.claim.acknowledgementDetails.requestedAppointmentDt = new Date(
					data.claim.acknowledgementDetails.requestedAppointmentDt
				);
				data.claim.damageInfo.forEach(damageInfo => {
					//@ts-ignore
					damageInfo.id = crypto.randomUUID();
				});
				return data.claim;
			});
		},
		defaultClaimDetailsState,
		{
			immediate: false,
			shallow: false
		}
	);

	const hasEmptyFields = computed(() => {
		return checkFieldsEmpty(
			fieldsToCheck,
			claim.state.value.additionalInfo,
			claim.state.value.acknowledgementDetails,
			claim.state.value.insurance,
			...claim.state.value.damageInfo
		);
	});

	const addClaimDamageInfo = () => {
		claim.state.value.damageInfo.push({
			glassDamageQuantity: 1,
			//@ts-ignore
			id: crypto.randomUUID(),
			openingTypeCode: OpeningTypeCode.OPENING_TYPE_CODE_WR,
			positionCode: PositionCode.POSITION_CODE_B,
			relativeLocationCode: RelativeLocationCode.RELATIVE_LOCATION_CODE_IN,
			repairQualification: false,
			sideCode: SideCode.SIDE_CODE_C
		});
	};

	const removeClaimDamageInfo = (index: number) => {
		claim.state.value.damageInfo.splice(index, 1);
	};

	const restartClaim = () => {
		claim.state.value.result.status = ClaimStatus.CLAIM_STATUS_NEW;
	};

	const createClaim = async (params: CreateClaimRequest) => {
		const unlinkedParams = JSON.parse(JSON.stringify(params));

		try {
			unlinkedParams.acknowledgementDetails.requestedAppointmentDt = new Date(
				unlinkedParams.acknowledgementDetails.requestedAppointmentDt
			).toISOString();
			//@ts-ignore
			unlinkedParams.damageInfo.forEach(damageInfo => {
				//@ts-ignore
				delete damageInfo.id;
			});
			await create(unlinkedParams);
		} catch (error) {
			console.error('Error');
			throw error;
		}
	};

	return {
		addClaimDamageInfo,
		claim,
		createClaim,
		hasEmptyFields,
		removeClaimDamageInfo,
		restartClaim
	};
});

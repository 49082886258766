import { gql } from '@apollo/client/core';

import { ADDRESS_FRAGMENT } from '@/entities/address/graphql/fragments/address.fragment';
import { COMMISSION_FRAGMENT } from '@/shared/graphql/fragments/commission.fragment';
import { EMAIL_FRAGMENT } from '@/shared/graphql/fragments/email.fragment';
import { PHONE_FRAGMENT } from '@/shared/graphql/fragments/phone.fragment';

export const GB_VENDOR_DEFAULT_LOCATION_BASE_FRAGMENT = gql`
	fragment GbVendorDefaultLocationBaseFields on GbVendor {
		id
		created
		modified
		organization_id
		is_disabled
		is_csr
		is_salesource
		is_salesrep
		is_tech
		is_location
		is_supplier
		name
		old_id
		commission {
			...CommissionFields
		}
		chip_commission {
			...CommissionFields
		}
	}
	${COMMISSION_FRAGMENT}
`;

export const GB_VENDOR_SHOP_SETTINGS_FRAGMENT = gql`
	fragment GbVendorShopSettingsFields on GbVendorShopSettings {
		id
		gb_vendor_id
		shop_id
		override_allowed_shop
	}
`;

export const GB_VENDOR_DEFAULT_SALESREP_BASE_FRAGMENT = gql`
	fragment GbVendorDefaultSalesRepBaseFields on GbVendor {
		id
		created
		modified
		organization_id
		is_disabled
		is_csr
		is_salesource
		is_salesrep
		is_tech
		is_location
		is_supplier
		name
		old_id
		commission {
			...CommissionFields
		}
		chip_commission {
			...CommissionFields
		}
		default_location {
			...GbVendorDefaultLocationBaseFields
		}
	}
	${COMMISSION_FRAGMENT}
	${GB_VENDOR_DEFAULT_LOCATION_BASE_FRAGMENT}
`;

export const GB_VENDOR_BASE_FRAGMENT = gql`
	fragment GbVendorBaseFields on GbVendor {
		id
		created
		modified
		organization_id
		is_disabled
		is_csr
		is_salesource
		is_salesrep
		is_tech
		is_location
		is_supplier
		name
		override_allowed_shops
		old_id
		commission {
			...CommissionFields
		}
		chip_commission {
			...CommissionFields
		}
		default_location {
			...GbVendorDefaultLocationBaseFields
		}
		default_salesrep {
			...GbVendorDefaultSalesRepBaseFields
		}
	}
	${COMMISSION_FRAGMENT}
	${GB_VENDOR_DEFAULT_SALESREP_BASE_FRAGMENT}
	${GB_VENDOR_DEFAULT_LOCATION_BASE_FRAGMENT}
`;

export const GB_VENDOR_FRAGMENT = gql`
	fragment GbVendorFields on GbVendor {
		...GbVendorBaseFields
		address {
			...AddressFields
		}
		addresses {
			...AddressFields
		}
		phone {
			...PhoneFields
		}
		phones {
			...PhoneFields
		}
		fax {
			...PhoneFields
		}
		faxes {
			...PhoneFields
		}
		email {
			...EmailFields
		}
		emails {
			...EmailFields
		}
		notes
		username
		invitation_status
		hide_completed_jobs
		color
		require_tech_pickup
		require_clock_in
		require_pre_inspection
		techside_hide_prices
		contact
		national_vendor_id
		short_code
		last_deliver_to_id
		hide_sent_ssm_jobs
		default_location {
			...GbVendorBaseFields
		}
		default_salesrep {
			...GbVendorBaseFields
		}
		commercialaccounts {
			id
			company_name
			reserve_balance
		}
		locations {
			...GbVendorBaseFields
		}
		salesources {
			...GbVendorBaseFields
		}
		mobileShopAccess {
			id
			gb_vendor_id
			shop_id
		}
		gb_vendor_shop_settings {
			...GbVendorShopSettingsFields
		}
	}
	${GB_VENDOR_BASE_FRAGMENT}
	${GB_VENDOR_SHOP_SETTINGS_FRAGMENT}
	${ADDRESS_FRAGMENT}
	${EMAIL_FRAGMENT}
	${PHONE_FRAGMENT}
	${COMMISSION_FRAGMENT}
`;

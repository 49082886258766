import singleSpaVue from 'single-spa-vue';
import { createApp, h } from 'vue';
import './app/assets/styles/index.css';

import initApp from './app';
import App from './app/App.vue';

const vueLifecycles = singleSpaVue({
	appOptions: {
		render() {
			return h(App, {});
		}
	},
	createApp,
	handleInstance(app) {
		initApp(app);
	}
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

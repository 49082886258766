<script setup lang="ts">
import Badge from 'primevue/badge';
import DatePicker from 'primevue/datepicker';
import Fieldset from 'primevue/fieldset';
import InputText from 'primevue/inputtext';
import ToggleButton from 'primevue/togglebutton';
import { defineModel, defineProps } from 'vue';
import { useI18n } from 'vue-i18n';

import { isEmptyValue } from '@/shared/helpers';

const { t } = useI18n();

const acknowledgementDetails = defineModel<{
	mobileIndicator: boolean;
	requestedAppointmentDt: string;
	workLocationPostalCode: string;
}>('acknowledgementDetails', { required: true });

defineProps<{ status: boolean }>();
</script>

<template>
	<Fieldset :legend="t('autoAccept')" toggleable>
		<div class="tw3-grid tw3-basis-2/3 tw3-gap-2">
			<div>
				<div
					class="tw3-h-8 tw3-relative tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
				>
					<span>{{ t('workLocation') }}</span>
					<Badge
						v-if="isEmptyValue(acknowledgementDetails.workLocationPostalCode)"
						v-tooltip.top="t('required')"
						class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
						severity="danger"
					/>
				</div>
				<InputText
					v-model="acknowledgementDetails.workLocationPostalCode"
					class="tw3-w-full"
					:disabled="status"
					type="text"
				/>
			</div>
			<div>
				<div
					class="tw3-h-8 tw3-relative tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
				>
					<span>{{ t('appointmentDate') }}</span>
					<Badge
						v-if="isEmptyValue(acknowledgementDetails.requestedAppointmentDt)"
						v-tooltip.top="t('required')"
						class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
						severity="danger"
					/>
				</div>

				<DatePicker
					v-model="acknowledgementDetails.requestedAppointmentDt"
					class="tw3-w-full"
					dateFormat="yy/mm/dd"
					:disabled="status"
					hourFormat="12"
					showTime
				/>
			</div>
			<div>
				<div
					class="tw3-h-8 tw3-relative tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
				>
					<span>{{ t('mobileIndicator') }}</span>
					<Badge
						v-if="isEmptyValue(acknowledgementDetails.mobileIndicator)"
						v-tooltip.top="t('required')"
						class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
						severity="danger"
					/>
				</div>

				<ToggleButton
					v-model="acknowledgementDetails.mobileIndicator"
					:disabled="status"
					:offLabel="t('no')"
					:onLabel="t('yes')"
				/>
			</div>
		</div>
	</Fieldset>
</template>

export const getAuth = (key: string): string | null | undefined => {
	const gboauth = localStorage.getItem('gboauth');

	if (gboauth === null) {
		return null;
	}

	let obj;

	try {
		obj = JSON.parse(gboauth);
	} catch (error) {
		console.error(error);
		return null;
	}

	return obj[key];
};

<script setup lang="ts">
import Dialog from 'primevue/dialog';
import { defineModel } from 'vue';

import QuotesTable from '@/widgets/quoteTable/QuotesTable.vue';

const showModal = defineModel<boolean>('show');
</script>

<template>
	<Dialog
		id="vue3QuoteDialog"
		v-model:visible="showModal"
		appendTo="#vue3app"
		class="tw3-w-full tw3-max-w-[1440px]"
		header="Quotes"
		modal
	>
		<QuotesTable />
	</Dialog>
</template>

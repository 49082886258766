export const vehicleTypes = [
	{
		label: 'Standard',
		value: 'VEHICLE_TYPE_STANDARD'
	},
	{
		label: 'RV',
		value: 'VEHICLE_TYPE_RV'
	}
];

export const vehicleOwnerships = [
	{ label: 'Owner', value: 'VEHICLE_OWNERSHIP_OWNER' },
	{ label: 'Commercial', value: 'VEHICLE_OWNERSHIP_COMMERCIAL' },
	{ label: 'Rental', value: 'VEHICLE_OWNERSHIP_RENTAL' },
	{ label: 'Borrowed', value: 'VEHICLE_OWNERSHIP_BORROWED' },
	{ label: '3rd Party', value: 'VEHICLE_OWNERSHIP_THIRD_PARTY' }
];
